<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { useUser } from "@/composables/useUser";
import { QuizStatus } from "@/types";

import CompletedTab from "@/components/lab/tabs/CompletedTab.vue";
import PendingTab from "@/components/lab/tabs/PendingTab.vue";

const { t } = useI18n();
const { user } = useUser();

const activeTab = ref<QuizStatus>(QuizStatus.PENDING);

const tabs: { title: string; status: QuizStatus }[] = [
  {
    title: "En cours",
    status: QuizStatus.PENDING,
  },
  {
    title: "Terminé",
    status: QuizStatus.RESULTS_AVAILABLE,
  },
];

const firstname = computed(() => {
  const temp = user.value?.first_name || "utilisateur";

  // Capitalize the first letter of the user's first name
  return temp.replace(/(^|-)([a-z])/g, (match) => match.toUpperCase());
});
</script>

<template>
  <main class="flex w-full flex-col">
    <div class="bg-gradient-to-tr from-[#04478A] to-[#401F52] px-5 py-8 lg:p-20">
      <div
        class="mx-auto flex w-full flex-col bg-[#ffffff17] p-8 backdrop-blur-xl lg:max-w-[1352px] lg:p-10"
      >
        <h2
          class="mb-5 text-[32px] font-bold leading-[38px] lg:text-center lg:text-[42px] lg:leading-[50px]"
        >
          Bonjour {{ firstname }},
        </h2>

        <p class="text-sm/5 lg:mx-auto lg:max-w-xl lg:text-center lg:text-base">
          {{ t("clientLab.welcomeMessage") }}
        </p>
      </div>
    </div>

    <section class="flex flex-col bg-blue-darker-lbp px-5 py-12 lg:px-20">
      <p
        class="mb-8 text-sm lg:mx-auto lg:max-w-3xl lg:text-center lg:text-base/5"
        v-html="t('clientLab.description')"
      />

      <h1 class="mb-8 text-center text-2xl font-bold lg:text-[32px] lg:leading-[40px]">
        {{ t("clientLab.surveysTitle") }}
      </h1>

      <div class="mx-auto mb-8 flex gap-5">
        <button
          v-for="tab in tabs"
          :key="tab.status"
          type="button"
          class="flex items-center justify-center rounded-full border border-transparent px-5 py-2 text-sm/none font-bold transition-all duration-200 ease-out hover:border-white lg:text-lg/none"
          :class="{
            'bg-white text-blue-darker-lbp': activeTab === tab.status,
            'bg-blue-darker-lbp text-white': activeTab !== tab.status,
          }"
          @click="activeTab = tab.status"
        >
          {{ tab.title }}
        </button>
      </div>

      <Transition name="slide-fade" mode="out-in">
        <PendingTab v-if="activeTab === QuizStatus.PENDING" />

        <CompletedTab v-else-if="activeTab === QuizStatus.RESULTS_AVAILABLE" />
      </Transition>
    </section>
  </main>
</template>
